<template>
  <div class="vtour-attr-pannel">
    <div class="title">{{ currentVideo.name }}</div>
    <div class="video-wrapper">
      <vue-aliplayer-v2
        ref="videoPlayer"
        :source="currentVideo.url"
        :options="options"
        @ready="handleReady"
      />
    </div>
  </div>
</template>

<script>
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2,
  },
  data() {
    return {
      options: {
        height: "100%",
        isLive: true,
        format: "m3u8",
        autoplay: true,
        skinLayout: false,
      },
      currentVideo: {
        id: "1-1",
        name: "摄像头1",
        url: "http://hls01open.ys7.com/openlive/3f3d281820d54ba6a5e3397c71fc7a28.m3u8", // aba63959abe54a1eb09ffd23971fcd57
      },
    };
  },
  mounted() {},
  methodds: {
    handleReady(){
        this.$refs.videoPlayer.play();
    }
  },
};
</script>

<style lang="less" scoped>
.vtour-attr-pannel {
  width: 100%;
  height: 100%;
  background: url(../../../../../assets/images/single/jsc/bg_vtour.png)
    no-repeat;
  background-size: 100% 100%;
  .title {
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
  }
  .video-wrapper {
    width: 100%;
    height: calc(100% - 40px);
    padding: 5px 5px 0;
  }
}
</style>